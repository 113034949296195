import React from "react";
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { Button, Hero, SectionHeader } from "../components/common";

import OpenedEnvelope from "../assets/images/vectors/opened-envelope.svg";

import "./refund.css";
import { getStaticSEOImage } from "../helpers";

function RefundPage({ location }) {
  return (
    <Layout>
      <SEO
        keywords={[`vackground`, `pattern`, `background`, `design`]}
        title="Refund"
        image={getStaticSEOImage("refund")}
        path={location?.pathname}
      />
      <section className="mt-24 mb-24">
        <Hero
          subTitle="No Worries"
          title={
            <>
              Refund Policy
              <br />
              Get Your Money Back
            </>
          }
        />
      </section>
      <section className="mb-32">
        <div className="dev-vg-refund__container m-auto font-normal text-lg leading-8 text-ui-gray-plus-4 tracking-tightsy">
          Thank you for shopping at Vackground.
          <br />
          <br />
          If, for any reason, you are not completely satisfied with a purchase
          within 60 days, we invite you to request a refund.
        </div>
      </section>
      <section className="mb-24">
        <div className="mb-8">
          <SectionHeader
            art
            artVector={OpenedEnvelope}
            title={
              <>
                Please Contact us to Get Your
                <br />
                Refund Money
              </>
            }
          />
        </div>
        <div className="flex items-center justify-center">
          <Button to="/contact" hasMoustache icon="vg-mail" text="Contact Us" />
        </div>
      </section>
    </Layout>
  );
}

RefundPage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default RefundPage;
